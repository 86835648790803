import React from "react";
import { motion } from "framer-motion";
import {
  Mail,
  Phone,
  MapPin,
  Briefcase,
  GraduationCap,
  Code,
  Award,
} from "lucide-react";
import CvButton from "../components/CvButton";

function Resume() {
  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <div className="min-h-screen  flex flex-col">
      <main className="px-4 py-8 text-gray-300 my-24">
        <motion.div
          className="max-w-5xl mx-auto"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {/* Header */}
          <motion.div
            className="text-center mb-12"
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <h1 className="text-5xl md:text-6xl font-bold mb-4 bg-gradient-to-r from-orange-500 to-pink-500 text-transparent bg-clip-text">
              Somesh Dahatonde
            </h1>
            <p className="md:text-xl text-gray-400">
              Full-Stack Developer | React.js | Next.js | Node.js | Express.js |
              RESTful APIs | Responsive Web Design
            </p>
          </motion.div>

          {/* Contact Information */}
          <motion.div
            className="flex flex-wrap justify-center gap-6 mb-12"
            variants={fadeInUp}
          >
            <a
              href="mailto:sddahatonde22@gmail.com"
              className="flex items-center bg-zinc-800 px-4 py-2 rounded-full hover:bg-zinc-700 transition-colors"
            >
              <Mail className="w-5 h-5 mr-2" />
              sddahatonde22@gmail.com
            </a>
            <a
              href="tel:7387130102"
              className="flex items-center bg-zinc-800 px-4 py-2 rounded-full hover:bg-zinc-700 transition-colors"
            >
              <Phone className="w-5 h-5 mr-2" />
              7387130102
            </a>
            <div className="flex items-center bg-zinc-800 px-4 py-2 rounded-full">
              <MapPin className="w-5 h-5 mr-2" />
              Pune, Maharashtra, India
            </div>
            <CvButton />
          </motion.div>

          {/* Main Content */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Left Column */}
            <div>
              <motion.section className="mb-12" variants={fadeInUp}>
                <div className="flex items-center mb-4">
                  <Code className="w-6 h-6 mr-2 text-orange-500" />
                  <h2 className="text-2xl font-semibold text-white">Skills</h2>
                </div>
                <div className="bg-zinc-800 rounded-lg p-6 shadow-lg">
                  <ul className="grid grid-cols-2 gap-2">
                    {[
                      "HTML",
                      "CSS",
                      "JavaScript",
                      "React.js",
                      "Next.js",
                      "Svelte.js",
                      "Node.js",
                      "Express.js",
                      "MongoDB",
                      "MySQL",
                      "RESTful APIs",
                      "Git & Gitlab",
                      "AWS & Serverless",
                      "Docker",
                      "UI/UX Design",
                      "Responsive Web Design",
                    ].map((skill, index) => (
                      <motion.li
                        key={index}
                        className="bg-zinc-700 px-3 py-1 rounded text-sm "
                        whileHover={{ scale: 1.05 }}
                      >
                        {skill}
                      </motion.li>
                    ))}
                  </ul>
                </div>
              </motion.section>
              <motion.section variants={fadeInUp}>
                <div className="flex items-center mb-4">
                  <Award className="w-6 h-6 mr-2 text-orange-500" />
                  <h2 className="text-2xl font-semibold text-white">
                    Projects
                  </h2>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-2xl mx-auto">
                  {[
                    {
                      title: "Delhi High Court e-District Portal",
                      url: "https://edhcr.nic.in/",
                      tech: "Next.js, SCSS, Docker, i18n, NextRoute, SSR",
                    },
                    {
                      title: "ConnectFM Maintenance",
                      url: "https://connectfm.ca/",
                      tech: "React.js, Nginx, Docker",
                    },
                    {
                      title: "Data Insiders",
                      url: "https://www.datainsiders.in/",
                      tech: "WordPress, Elementor, Razorpay",
                    },
                    {
                      title: "Room Wala",
                      url: "https://findmate.netlify.app/",
                      tech: "Next.js, Tailwind CSS, NextAuth.js, NextApi Routes, MySql, Aws S3, Aws RDS",
                    },
                  ].map((project, index) => (
                    <motion.div
                      key={index}
                      className="bg-zinc-800 p-4 rounded-lg shadow-lg overflow-hidden"
                      whileHover={{ scale: 1.05 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <h3 className="text-lg font-semibold text-white mb-2">
                        {project.title}
                      </h3>
                      <a
                        href={project.url}
                        className="text-sm text-orange-300 hover:underline block mb-2 break-words"
                      >
                        {project.url}
                      </a>
                      <p className="text-sm">
                        <strong>Tech:</strong> {project.tech}
                      </p>
                    </motion.div>
                  ))}
                </div>
              </motion.section>
              <motion.section className="my-12" variants={fadeInUp}>
                <div className="flex items-center mb-4">
                  <GraduationCap className="w-6 h-6 mr-2 text-orange-500" />
                  <h2 className="text-2xl font-semibold text-white">
                    Education
                  </h2>
                </div>
                <div className="bg-zinc-800 rounded-lg p-6 shadow-lg space-y-4">
                  <div>
                    <h3 className="text-xl font-semibold text-white">
                      BE, Information Technology
                    </h3>
                    <p className="text-sm text-gray-400">
                      H.V.P.M College of Engineering & Technology, Amravati
                    </p>
                    <p>Aug 2020 - Jan 2024</p>
                    <p className="text-orange-500">CGPA: 7.57/10</p>
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold text-white">
                      Higher Secondary (HSC)
                    </h3>
                    <p className="text-sm text-gray-400">
                      Shri Suresh Bhau Jain Jr Science College, Shelubazar
                    </p>
                    <p>Jun 2020</p>
                    <p className="text-orange-500">Score: 69.69/100%</p>
                  </div>
                </div>
              </motion.section>
            </div>

            {/* Right Column */}
            <div>
              <motion.section className="mb-12" variants={fadeInUp}>
                <div className="flex items-center mb-4">
                  <Briefcase className="w-6 h-6 mr-2 text-orange-500" />
                  <h2 className="text-2xl font-semibold text-white">
                    Work Experience
                  </h2>
                </div>
                <div className="space-y-6">
                  {[
                    {
                      title: "Full-Stack Developer",
                      company: "Digital HackZone Pvt. Ltd.",
                      period: "Feb 2023 - Current",
                      responsibilities: [
                        "Developed web applications using HTML, CSS , JavaScript. ",
                        "Developed efficient database schemas to store data from the web applications.",
                        "Researched and implemented new technologies to improve development processes.",
                        "Created unit tests for full stack web application components.",
                        "Worked together with other developers on code reviews, bug fixes, and feature development.",
                        "Deployed web applications to production environments using various cloud services such as AWS and Azure.",
                        "Web technology stack: React.js, Next.js , Node.js, Express.js, MongoDB, MySQL, RESTful APIs, Docker, AWS, Azure.",
                      ],
                    },
                    {
                      title: "Full-Stack Developer Intern",
                      company: "ewslab",
                      period: "Sep 2023 - Feb 2024",
                      responsibilities: [
                        "Developed back-end services utilizing Node.js and Express for data manipulation and business logic.",
                        "Designed and implemented MongoDB and MySql database schemas to efficiently store application data.",
                        "Implemented Restful Web Services to integrate APIs with web applications.",
                        "Created responsive and scalable web applications with HTML, CSS, JavaScript and ReactJS.",
                      ],
                    },
                  ].map((job, index) => (
                    <motion.div
                      key={index}
                      className="bg-zinc-800 rounded-lg p-6 shadow-lg"
                      whileHover={{ scale: 1.02 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      <h3 className="text-xl font-semibold text-white">
                        {job.title}
                      </h3>
                      <p className="text-sm text-gray-400">
                        {job.company} | {job.period}
                      </p>
                      <ul className="list-disc list-inside mt-2 space-y-1">
                        {job.responsibilities.map((resp, i) => (
                          <li key={i} className="text-sm">
                            {resp}
                          </li>
                        ))}
                      </ul>
                    </motion.div>
                  ))}
                </div>
              </motion.section>
            </div>
          </div>
        </motion.div>
      </main>
    </div>
  );
}

export default Resume;
